import React , {useState} from 'react';
import './css/App.css';


const SearchBar = ({ classname, searchQuery, setSearchQuery, handleSearch, isMobile, handleFilter }) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className={classname}>
            <div className="search-wrapper">
                {/* for desktop */}
                {!isMobile && !searchQuery && !isFocused && (<label for="search-input" className={"search-placeholder"}>
                    <span style={{color:'black'}}>LOCATE</span> <span style={{color:'#404040'}}>ANY</span> THING 
                </label>)}
                {/* for mobile */}
                {isMobile && !searchQuery && !isFocused &&(<div className="search-placeholder">
                <span className='placeholder-line-m1'><span style={{ color: 'black' }}>LOCATE</span> <span style={{ color: '#404040' }}>ANY</span> THING</span> 
                </div>)}
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {setSearchQuery(e.target.value); handleFilter();}}
                    onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder=" "
                    className="search-input"
                    data-toggle="tooltip"
                    data-placement="top"
                    // title="The more search terms you enter, the better results you will get. For example: if you're looking for a used item, put 'used'; if a specific brand, specify the brand, etc."
                />
                <button onClick={handleSearch} className="search-button">
                    <i className="fa fa-search" />
                </button>
            </div>
        </div>
    );
};

export default SearchBar;