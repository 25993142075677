export const categories = [
    'antiques', 'appliances', 'arts+crafts', 'atv/utv/sno', 'auto parts', 'aviation',
    'baby+kid', 'barter', 'beauty+hlth', 'bike parts', 'bikes', 'boat parts', 'boats', 'books',
    'business', 'cars+trucks', 'cds/dvd/vhs', 'cell phones', 'clothes+acc', 'collectibles',
    'computer parts', 'computers', 'electronics', 'farm+garden', 'free', 'furniture', 'garage sale',
    'general', 'heavy equip', 'household', 'jewelry', 'materials', 'motorcycle parts', 'motorcycles',
    'music instr', 'photo+video', 'rvs+camp', 'sporting', 'tickets', 'tools', 'toys+games', 'trailers',
    'video gaming', 'wanted', 'wheels+tires'
];


export const languageOptions = {
    "English": "English",
    "Afrikaans": "Afrikaans",
    "Albanian": "Shqip",
    "Arabic": "العربية",
    "Armenian": "Հայերեն",
    "Basque": "Euskara",
    "Bengali": "বাংলা",
    "Bulgarian": "Български",
    "Catalan": "Català",
    "Cambodian": "ភាសាខ្មែរ",
    "Chinese (Mandarin)": "中文 (普通话)",
    "Croatian": "Hrvatski",
    "Czech": "Čeština",
    "Danish": "Dansk",
    "Dutch": "Nederlands",
    "Estonian": "Eesti",
    "Fiji": "Na Vosa Vakaviti",
    "Finnish": "Suomi",
    "French": "Français",
    "Georgian": "ქართული",
    "German": "Deutsch",
    "Greek": "Ελληνικά",
    "Gujarati": "ગુજરાતી",
    "Hebrew": "עברית",
    "Hindi": "हिन्दी",
    "Hungarian": "Magyar",
    "Icelandic": "Íslenska",
    "Indonesian": "Bahasa Indonesia",
    "Irish": "Gaeilge",
    "Italian": "Italiano",
    "Japanese": "日本語",
    "Javanese": "Basa Jawa",
    "Korean": "한국어",
    "Latin": "Latine",
    "Latvian": "Latviešu",
    "Lithuanian": "Lietuvių",
    "Macedonian": "Македонски",
    "Malay": "Bahasa Melayu",
    "Malayalam": "മലയാളം",
    "Maltese": "Malti",
    "Maori": "Te Reo Māori",
    "Marathi": "मराठी",
    "Mongolian": "Монгол",
    "Nepali": "नेपाली",
    "Norwegian": "Norsk",
    "Persian": "فارسی",
    "Polish": "Polski",
    "Portuguese": "Português",
    "Punjabi": "ਪੰਜਾਬੀ",
    "Quechua": "Runa Simi",
    "Romanian": "Română",
    "Russian": "Русский",
    "Samoan": "Gagana Sāmoa",
    "Serbian": "Српски",
    "Slovak": "Slovenčina",
    "Slovenian": "Slovenščina",
    "Spanish": "Español",
    "Swahili": "Kiswahili",
    "Swedish": "Svenska",
    "Tamil": "தமிழ்",
    "Tatar": "Татарча",
    "Telugu": "తెలుగు",
    "Thai": "ภาษาไทย",
    "Tibetan": "བོད་སྐད་",
    "Tonga": "Lea Faka-Tonga",
    "Turkish": "Türkçe",
    "Ukrainian": "Українська",
    "Urdu": "اردو",
    "Uzbek": "O‘zbek",
    "Vietnamese": "Tiếng Việt",
    "Welsh": "Cymraeg",
    "Xhosa": "isiXhosa"
};