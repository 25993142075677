import React , {useState} from "react";
import './css/Contact-Form.css';

const ContactForm = ({ closeContactForm }) => {
    const [formData, setFormData] = useState({
        sender_name: "",
        sender_email: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch("https://api.mapr.ad/send-email/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
                mode: 'cors',
            });

            if (response.ok) {
                alert("Thank you! Your message has been sent.");
                setFormData({ sender_name: "", sender_email: "", message: "" });
                closeContactForm();
            } else {
                alert("Something went wrong. Please try again.");
            }
        } catch (error) {
            alert("Error submitting the form. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="contact-form-container">
            <button onClick={closeContactForm} className="close-button-contact">
                ✖
            </button>
            <form onSubmit={handleSubmit}>
                <label>Name:</label>
                <input type="text" name="sender_name" value={formData.sender_name} onChange={handleChange} required />
                <label>Email:</label>
                <input type="email" name="sender_email" value={formData.sender_email} onChange={handleChange} required />
                <label>Message:</label>
                <textarea name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
                <button className='submit-button-contact' type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Submit"}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
