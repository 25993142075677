import React, {useEffect, useState} from 'react';

const Tooltip = ({ isMobile , handleClose}) => {
    const tooltipStyleContent = isMobile ? {} : { fontSize: '1.36em' };

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setFadeIn(true), 100);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={`tooltip ${fadeIn ? "fade-in" : ""}`}>
            <button className="close-btn-tt" onClick={handleClose}>
                &times;
            </button>
            <div style={tooltipStyleContent} className="tooltip-content">
                <p>Some basic tips:<br />
                ‣ The more search terms the better<br />
                ‣ Three minimum recommended<br />
                ‣ Used/new, size, model, features...<br />
                ‣ Location & language icons on top<br />
                ‣ Category selector is WIP<br />
                ‣ Keep your OS/browser updated<br />
                ‣ Pop-up blockers may cause issues<br />
                ‣ Data volume may slow the search<br />
                ‣ MapR USES NO COOKIES!<br />
                    
                </p>
            </div>
        </div>
    );
}


export default Tooltip;